import DeliveryDate from '@theme/components/shop/DeliveryDate'

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    DeliveryDate,
  },
}
