import { get } from 'lodash'
import AddToCart from '@theme/components/cart/AddToCart'
import AppImage from '@theme/components/atom/AppImage'
import AppNumber from '@theme/components/atom/AppNumber'
import ProductVariantSelect from '@theme/components/product/ProductVariantSelect'

export default {
  props: {
    decimalPosition: {
      type: Number,
      default() {
        return this.$themeSettings.components.ProductMetersCalculator.decimalPosition
      },
    },
    parameter: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    variant: {
      type: Object,
      required: true,
    },
  },
  components: {
    AddToCart,
    AppImage,
    AppNumber,
    ProductVariantSelect,
  },
  data() {
    return {
      quantity: 0,
    }
  },
  created() {
    this.quantity = this.quantityMin
  },
  watch: {
    square: {
      immediate: true,
      handler(newVal, oldVal) {
        this.$emit('updateQuantity', newVal)
      },
    },
  },
  computed: {
    square() {
      return Number((this.quantity * this.quantityMultiplier).toFixed(this.decimalPosition))
    },
    quantityMin() {
      const amountMin = Number(get(this.variant, 'availability.amountMin'))
      return amountMin && this.quantityMultiplier
        ? Number((amountMin / this.quantityMultiplier).toFixed(this.decimalPosition))
        : amountMin || 1
    },
    quantityMax() {
      const amountMax = Number(get(this.variant, 'availability.amountMax'))
      return amountMax && this.quantityMultiplier
        ? Number((amountMax / this.quantityMultiplier).toFixed(this.decimalPosition))
        : amountMax || Infinity
    },
    quantityMultiplier() {
      return Number(get(this.parameter, 'values')) || 1
    },
    quantityStep() {
      const amountStep = Number(get(this.variant, 'availability.amountStep'))
      return amountStep && this.quantityMultiplier
        ? Number((amountStep / this.quantityMultiplier).toFixed(this.decimalPosition))
        : amountStep || 1
    },
    totalPriceWithoutTax() {
      return this.variant.prices.unitWithoutTax * this.square
    },
    totalOriginalPriceWithTax() {
      return this.variant.prices.originalWithTax * this.square
    },
    totalPriceWithTax() {
      return this.variant.prices.unitWithTax * this.square
    },
  },
  methods: {
    updateVariant($event) {
      this.$emit('updateVariant', null, $event)
    },
    updateQuantity($event) {
      this.quantity = $event
    },
  },
}
