export default {
  props: {
    counts: Array,
    totalReviewsCount: Number,
  },
  computed: {
    tableData() {
      const result = []
      this.counts &&
        this.counts.forEach((item, index) => {
          if (index % 2 !== 0) {
            result.push(item + this.counts[index - 1])
          }
        })
      return result
    },
  },
}
