var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.local.enable)?_c('div',{staticClass:"Sticky"},[_c('div',{ref:"holder",staticClass:"Sticky__holder",style:(_vm.holderStyle)}),_c('div',{ref:"element",staticClass:"Sticky__element",class:[
        { 'Sticky__element--preventTransition': _vm.preventTransition },
        { 'Sticky__element--notSticky': _vm.stickyState === _vm.stickyStateEnum.NOT_STICKY },
        { 'Sticky__element--sticky': _vm.stickyState >= _vm.stickyStateEnum.STICKY_NOT_READY },
        { 'Sticky__element--hidden': _vm.stickyState >= _vm.stickyStateEnum.STICKY_NOT_READY && _vm.stickyState < _vm.stickyStateEnum.STICKY_VISIBLE },
        { 'Sticky__element--active': _vm.stickyState === _vm.stickyStateEnum.STICKY_VISIBLE },
      ],style:(_vm.elementStyle)},[_vm._t("default",null,{"preventClose":_vm.preventClose})],2)]):_vm._t("default",null,{"preventClose":_vm.preventClose})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }