import { get } from 'lodash'
import AddToCart from '@theme/components/cart/AddToCart'
import AppNumber from '@theme/components/atom/AppNumber'

export default {
  name: 'ProductConfiguratorAddToCart',
  props: {
    accessoryProducts: {
      type: Array,
      default: () => [],
    },
    activeVariant: {
      type: Object,
      required: true,
    },
    addToCartCustomData: {
      type: Object,
      required: true,
    },
    enable: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.ProductConfigurator.addToCart.enable
      },
    },
    enablePrice: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.ProductConfigurator.addToCart.enablePrice
      },
    },
    filledRequiredParameters: {
      type: Boolean,
      default: true,
      required: true,
    },
    isStickyActive: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
    relatedProducts: {
      type: Array,
      default: () => [],
    },
    quantity: {
      type: Number,
      default: 1,
    },
    unit: {
      type: String,
      required: true,
    },
  },
  components: {
    AddToCart,
    AppNumber,
  },
  computed: {
    filledRequiredParametersComputed() {
      if (!this.filledRequiredParameters) {
        return false
      } else {
        return filledRequiredParameters
      }
    },
  },
}
