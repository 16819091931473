import { notifications } from '~/app/notifications'
import AppButton from '@theme/components/atom/AppButton'
import AppInput from '@theme/components/atom/AppInput'
import AppTextarea from '@theme/components/atom/AppTextarea'
import AppTitle from '@theme/components/atom/AppTitle'
import LoadingMixin from '~/mixins/LoadingMixin'
import Modal from '@theme/components/utils/Modal'
import StarRating from '@theme/components/utils/StarRating'

export default {
  mixins: [LoadingMixin],
  components: {
    AppButton,
    AppInput,
    AppTextarea,
    AppTitle,
    StarRating,
    Modal,
  },
  props: {
    addReviewDisabled: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: Number,
      required: true,
    },
    showHintAboveAddReviewButton: {
      type: Boolean,
      default: false,
    },
    showStarsAboveAddReviewButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      review: {
        cons: '',
        pros: '',
        rating: 0,
        text: '',
      },
      sent: false,
    }
  },
  created() {
    this.CLASS_ELEMENT_TEXTAREA = 'col-24'
    this.CLASS_ELEMENT_INPUT = 'col-24 col-md-12'
    this.CLASS_ELEMENTS_OUTSIDE_NO_LABEL = 'col-24'
    this.CLASS_LABEL = 'col-form-label col-24 mb-0'
    this.CLASS_ROW = 'row mb-2'
  },
  methods: {
    openModal() {
      this.$refs.modal.show()
    },
    async sendData() {
      const validation = await this.$validator.validate()
      if (!validation) return
      this.startLoading()
      const response = await this.$store.dispatch('product/ADD_REVIEW', {
        productId: this.productId,
        review: {
          cons: this.consArray,
          pros: this.prosArray,
          rating: this.review.rating,
          text: this.review.text,
        },
      })
      if (response) {
        this.sent = true
        this.review = {
          cons: '',
          pros: '',
          rating: 0,
          text: '',
        }
      }
      this.stopLoading()
    },
  },
  computed: {
    consArray() {
      return this.review.cons ? this.review.cons.split('\n') : null
    },
    prosArray() {
      return this.review.pros ? this.review.pros.split('\n') : null
    },
    textHint() {
      return this.$options.filters.prepositions(
        this.addReviewDisabled ? this.$t('ProductAddReview.loginToWriteReview') : this.$t('ProductAddReview.hint'),
      )
    },
  },
}
