<script>
import FoxentryInputMixin from '~/mixins/FoxentryInputMixin'
import InjectValidator from '~/app/InjectValidator'
import _ from 'lodash'

export default {
  mixins: [FoxentryInputMixin, InjectValidator],
  inheritAttr: false,
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    validation: {
      type: [String, Object],
      default: '',
    },
    value: {
      type: [Number, Object, String],
      default: null,
    },
  },
  data() {
    return {
      val: _.clone(this.value),
    }
  },
  watch: {
    value(newValue) {
      this.val = _.clone(newValue)
    },
    val(newValue) {
      this.$emit('input', newValue)
    },
  },
}
</script>
