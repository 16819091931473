import { clone } from 'lodash'
import AppSelect from '@theme/components/atom/AppSelect'

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    variant: {
      type: Object,
      default: null,
    },
  },
  components: {
    AppSelect,
  },
  data() {
    return {
      val: clone(this.variant),
    }
  },
}
