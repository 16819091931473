import DeliveryDate from '@theme/components/shop/DeliveryDate'

export default {
  components: {
    DeliveryDate,
  },
  props: {
    variantsAvailabilityInWarehouses: {
      type: Object,
      required: true,
    },
    selectedVariant: {
      type: Object,
      required: true,
    },
  },
}
