var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ProductConfigurator"},[_c('div',{staticClass:"ProductConfigurator__content"},[_c('AppTitle',{staticClass:"ProductConfigurator__title Title--gama",attrs:{"heading-level":3}},[_vm._v(" "+_vm._s(_vm.$t('ProductConfigurator.title'))+" ")]),_c('div',{staticClass:"ProductConfigurator__parameters"},[(_vm.$themeSettings.components.ProductConfigurator.sizes.enable)?_c('div',{staticClass:"ProductConfigurator__parameter"},[_c('div',{staticClass:"ProductConfigurator__parameterHeading",on:{"click":function($event){_vm.sizesOpen = !_vm.sizesOpen}}},[_c('div',{staticClass:"ProductConfigurator__parameterHeadingTitle",domProps:{"innerHTML":_vm._s(_vm.$t('ProductConfigurator.sizes.title'))}}),_c('svgicon',{staticClass:"ml-auto",attrs:{"icon":_vm.toggleIcons[_vm.sizesOpen ? 'minus' : 'plus']}})],1),_c('transition',{attrs:{"name":"slide"}},[(_vm.sizesOpen)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"ProductConfigurator__parameterContent row"},[_vm._l((Object.keys(_vm.sizes)),function(item,index){return _c('div',{key:item,staticClass:"ProductConfigurator__parameterVariant col-auto"},[_c('div',{staticClass:"row",class:{'ProductConfigurator__withButton': _vm.productSizeImage && index === 1}},[_c('div',{staticClass:"col-auto"},[(_vm.productSizeImage && index < 1)?_c('div',{staticClass:"ml-auto mb-2"},[_c('AppImage',{attrs:{"height":130,"src":_vm.productSizeImage,"width":130}})],1):_vm._e(),_c('div',{staticClass:"font-weight-bold text-left mb-2",domProps:{"innerHTML":_vm._s(_vm.$t('ProductConfigurator.sizes.' + item))}}),_c('AppNumber',{staticClass:"ProductConfigurator__parameterSize",attrs:{"initial":_vm.sizes[item],"max":_vm.getSizeLimit('max', item),"min":_vm.getSizeLimit('min', item),"notification-max-amount-reached":_vm.$t('ProductConfigurator.notification.sizeReached', {
                        label: _vm.$t('ProductConfigurator.notification.maximum'),
                        type: _vm.$t('ProductConfigurator.notification.' + item),
                        limit: _vm.getSizeLimit('max', item),
                        unit: 'cm'
                      }),"notification-min-amount-reached":_vm.$t('ProductConfigurator.notification.sizeReached', {
                        label: _vm.$t('ProductConfigurator.notification.minimum'),
                        type: _vm.$t('ProductConfigurator.notification.' + item),
                        limit: _vm.getSizeLimit('min', item),
                        unit: 'cm'
                      }),"unit":'cm'},on:{"update":function($event){_vm.sizes[item] = $event}}})],1),(_vm.productSizeImage && index === 1)?_c('div',{staticClass:"col-auto mt-auto"},[_c('AppButton',{staticClass:"ProductConfigurator__recalculateButton btn btn-primary btn-lg",nativeOn:{"click":function($event){return _vm.submitSize()}}},[_vm._v(" "+_vm._s(_vm.$t('ProductConfigurator.sizes.recalculate'))+" ")])],1):_vm._e()])])}),(!_vm.productSizeImage)?_c('div',{staticClass:"ProductConfigurator__parameterVariant col-auto d-flex align-items-end"},[_c('AppButton',{staticClass:"ProductConfigurator__recalculateButton btn btn-primary btn-lg",nativeOn:{"click":function($event){return _vm.submitSize()}}},[_vm._v(" "+_vm._s(_vm.$t('ProductConfigurator.sizes.recalculate'))+" ")])],1):_vm._e()],2)]):_vm._e()])],1):_vm._e(),_vm._l((_vm.parameters),function(parameter){return _c('div',{key:parameter._id,staticClass:"ProductConfigurator__parameter"},[_c('div',{staticClass:"ProductConfigurator__parameterHeading",on:{"click":function($event){return _vm.toggleOpen(parameter._id)}}},[_c('div',{staticClass:"ProductConfigurator__parameterHeadingTitle",domProps:{"innerHTML":_vm._s(parameter.title)}}),_c('div',{staticClass:"ProductConfigurator__parameterHeadingVariant",class:[
              {'ProductConfigurator__parameterHeadingVariant--required': parameter.required},
              {'ProductConfigurator__parameterHeadingVariant--selected': _vm.$options.filters.selected(parameter)}],domProps:{"innerHTML":_vm._s(_vm.$options.filters.selected(parameter) || (parameter.required ? _vm.$t('ProductConfigurator.requiredParameter') : ''))}}),(parameter.description)?_c('TooltipOpener',{attrs:{"tooltip-content":parameter.description.replace(/\n/g, '<br>'),"tooltip-icon":'info'}}):_vm._e(),_c('svgicon',{staticClass:"ml-auto",attrs:{"icon":_vm.toggleIcons[_vm.isOpen(parameter._id) ? 'minus' : 'plus']}})],1),_c('transition',{attrs:{"name":"slide"}},[(_vm.isOpen(parameter._id))?_c('div',{staticClass:"container"},[_c('div',{staticClass:"ProductConfigurator__parameterContent row"},_vm._l((parameter.items),function(item){return _c('div',{key:item._id,staticClass:"ProductConfigurator__parameterVariant col-24 col-md-12 col-lg-6",class:[
                  {'ProductConfigurator__parameterVariant--selected': item.attributes.selected}
                ],on:{"click":function($event){return _vm.select(parameter, item)}}},[_c('div',{staticClass:"row flex-nowrap"},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"custom-control checkbox-custom"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox"},domProps:{"checked":item.attributes.selected}}),_c('label',{staticClass:"pl-3 d-inline"})])]),_c('div',{staticClass:"px-2 text-center"},[(item.img)?_c('AppImage',{staticClass:"mx-auto mb-2",attrs:{"height":130,"src":item.img,"width":130}}):_vm._e(),_c('div',{staticClass:"ProductConfigurator__parameterVariantTitle text-left",domProps:{"innerHTML":_vm._s(item.title)}}),_c('div',{staticClass:"ProductConfigurator__parameterVariantPrice"},[_vm._v(" + "+_vm._s(_vm.getParameterPriceChange(item))+" "),_c('span',[_vm._v(_vm._s(_vm.$t('ProductConfigurator.price.withTax')))])])],1)])])}),0)]):_vm._e()])],1)})],2)],1),_c('Sticky',{ref:"stickyConfiguratorHeader",attrs:{"enable":_vm.$themeSettings.components.ProductConfigurator.addToCart.enableSticky,"always-visible":"","immediate-sticky":""},on:{"isStickyActive":_vm.detectStickyState}},[_c('ProductConfiguratorAddToCart',{attrs:{"accessory-products":_vm.accessoryProducts,"active-variant":_vm.displayedVariant,"add-to-cart-custom-data":_vm.addToCartCustomData,"configurator-data":_vm.configuratorData,"displayed-variant":_vm.displayedVariant,"filled-required-parameters":_vm.filledRequiredParameters,"is-sticky-active":_vm.isStickyActive,"product":_vm.product,"related-products":_vm.relatedProducts,"quantity":_vm.quantity,"unit":_vm.$t('globals.defaultProductUnit')}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }