import AppButton from '@theme/components/atom/AppButton'
import Loader from '@theme/components/utils/Loader'
import LoadingMixin from '~/mixins/LoadingMixin'
import Pagination from '@theme/components/molecule/Pagination'
import ProductAddReview from '@theme/components/product/ProductAddReview'
import ProductReviewBox from '@theme/components/product/ProductReviewBox'
import ProductReviewMixin from '~/mixins/ProductReviewMixin'
import RatingTable from '@theme/components/product/RatingTable'
import StarRating from '@theme/components/utils/StarRating'
import _ from 'lodash'

export default {
  components: {
    AppButton,
    Loader,
    Pagination,
    ProductAddReview,
    ProductReviewBox,
    RatingTable,
    StarRating,
  },
  mixins: [LoadingMixin, ProductReviewMixin],
  props: {
    productId: {
      type: Number,
      required: true,
    },
    productReviews: {
      type: Object,
      required: true,
    },
    conIcon: {
      type: String,
    },
    proIcon: {
      type: String,
    },
  },
  data() {
    return {
      averageRating: 0,
      counts: null,
      ratingBoxBreakpoint: 'xl',
      reviews: {},
      reviewsCount: 0,
      urlParameterName: 'r',
      valueToStarsFormatted: null,
    }
  },
  mounted() {
    this.reviews = this.productReviews
    this.averageRating = _.get(this.productReviews, 'meta.custom.summary.totalRating', 0)
    this.counts = _.get(this.productReviews, 'meta.custom.summary.counts')
    this.reviewsCount = _.get(this.productReviews, 'meta.custom.summary.totalCount', 0)
    this.valueToStarsFormatted = this.averageRating
      ? this.transformRatingToStars(this.averageRating).toLocaleString(this.$i18n.locale, {
          minimumFractionDigits: this.$themeSettings.components.ProductReviewList.starsRatingDecimalPlaces,
          maximumFractionDigits: this.$themeSettings.components.ProductReviewList.starsRatingDecimalPlaces,
        })
      : null
  },
  methods: {
    async changePage(page) {
      this.startLoading()
      const response = await this.$store.dispatch('product/LOAD_REVIEWS', {
        page: page + 1,
        productId: this.productId,
        perPage: this.reviews.meta.pagination.perPage,
      })
      this.reviews = response
      if (page === 0) {
        this.$router.replace({ path: this.$route.path, query: {} })
      } else {
        this.$router.replace({ path: this.$route.path, query: { [this.urlParameterName]: page } })
      }
      this.stopLoading()
    },
    async loadMoreReviews() {
      this.startLoading()
      const response = await this.$store.dispatch('product/LOAD_REVIEWS', {
        page: this.reviews.meta.pagination.currentPage + 1,
        productId: this.productId,
        perPage: this.reviews.meta.pagination.perPage,
      })
      this.$router.replace({
        path: this.$route.path,
        query: { [this.urlParameterName]: this.reviews.meta.pagination.currentPage },
      })
      this.reviews.data = [...this.reviews.data, ...response.data]
      this.reviews.meta = response.meta
      this.stopLoading()
    },
  },
}
