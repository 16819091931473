import { render, staticRenderFns } from "./ProductConfigurator.vue?vue&type=template&id=9ede232e&"
import script from "./ProductConfigurator.js?vue&type=script&lang=js&"
export * from "./ProductConfigurator.js?vue&type=script&lang=js&"
import style0 from "./ProductConfigurator.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ProductConfigurator.lang.cs.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fbuild%2Fthemes%2Fbase%2Fcomponents%2Fproduct%2FProductConfigurator%2FProductConfigurator.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports