import { findIndex, get, reduce } from 'lodash'
import { hydrateWhenVisible } from 'vue-lazy-hydration'
import AddToCart from '@theme/components/cart/AddToCart'
import AppButton from '@theme/components/atom/AppButton'
import AppImage from '@theme/components/atom/AppImage'
import AppNumber from '@theme/components/atom/AppNumber'
import AppTitle from '@theme/components/atom/AppTitle'
import ProductConfiguratorAddToCart from '@theme/components/product/ProductConfiguratorAddToCart'
import Sticky from '@theme/components/utils/Sticky'
import TooltipOpener from '@theme/components/molecule/TooltipOpener'

export default {
  name: 'ProductConfigurator',
  props: {
    accessoryProducts: {
      type: Array,
      default: () => [],
    },
    activeVariant: {
      type: Object,
      required: true,
    },
    configuratorData: {
      type: Object,
      required: true,
    },
    displayedVariant: Object,
    product: {
      type: Object,
      required: true,
    },
    relatedCategories: {
      type: Array,
      default: () => [],
    },
    relatedProducts: {
      type: Array,
      default: () => [],
    },
    quantity: {
      type: Number,
      default: 1,
    },
    unit: {
      type: String,
      required: true,
    },
  },
  components: {
    AddToCart,
    AppButton,
    AppImage,
    AppNumber,
    AppTitle,
    ProductConfiguratorAddToCart,
    Sticky: hydrateWhenVisible(Sticky),
    TooltipOpener,
  },
  data() {
    return {
      openedParams: [],
      isStickyActive: false,
      sizes: {
        width: this.product.attributes.width,
        height: this.product.attributes.height,
      },
      toggleIcons: {
        minus: require('@icon/minus.svg?raw'),
        plus: require('@icon/plus.svg?raw'),
      },
      sizesOpen: true,
    }
  },
  filters: {
    selected: function(val) {
      return val.items
        .filter(parameter => parameter.selected)
        .map(parameter => parameter.title)
        .join()
    },
  },
  computed: {
    addToCartCustomData() {
      let configuration = []
      this.configuration.forEach(c => {
        if (c.selected) {
          configuration.push({ code: c.code })
        }
      })
      return {
        width: this.productWidth,
        height: this.productHeight,
        configuration: configuration,
        externalId: this.product.externalId,
      }
    },
    configuration() {
      let configuration = []
      if (this.parameters && this.parameters.length) {
        this.parameters.forEach(p => {
          if (p.items && p.items.length) {
            p.items.forEach(i => {
              if (i.attributes) {
                configuration.push(i.attributes)
              }
            })
          }
        })
      }
      return configuration
    },
    filledRequiredParameters() {
      const required = this.parameters.filter(param => param.required)
      const selected = get(this.product, 'attributes.configuration', [])
        .filter(attr => attr.selected)
        .map(attr => attr.product_configuration_id)
      const groups = reduce(
        required,
        (acc, item) => [
          ...acc,
          {
            id: item._id,
            items: item.items.map(param => param._id),
          },
        ],
        [],
      )
      const { length } = groups.filter(group => !group.items.some(r => selected.includes(r)))
      return !length
    },
    productHeight() {
      return get(this.product, 'attributes.height', 0)
    },
    productWidth() {
      return get(this.product, 'attributes.width', 0)
    },
    parameters() {
      let parameters = []
      const productConfig = get(this.configuratorData, 'product_configurations_priced', [])
      const variantConfig = get(this.product, 'attributes.configuration', [])
      variantConfig.forEach(config => {
        const parameter = get(
          productConfig.reduce(
            (acc, item) => [
              ...acc,
              ...item.product_configuration_value_reference.filter(
                e => e.entity._id === config.product_configuration_id,
              ),
            ],
            [],
          ),
          '[0].entity',
        )
        const parameterType = get(parameter, 'product_configuration_type_reference.[0].entity') // Configuration type: (id, title & description)
        // Transform item
        const item = {
          attributes: config,
          img: get(parameter, 'product_configuration_value_image[0].path'),
          ...parameter,
        }
        const parameterIndex = findIndex(parameters, { _id: get(parameterType, '_id') }, 0)
        if (parameterIndex !== -1) {
          parameters[parameterIndex].items.push(item)
        } else {
          parameters.push({
            ...parameterType,
            items: [item],
          })
        }
      })
      return parameters
    },
    productSizeImage() {
      return (
        get(this.relatedCategories, '[0].content.product_size_image[0].fileKey') ||
        get(this.$store, 'state.globals.configuration_products.product_size_image[0].path', false)
      )
    },
  },
  methods: {
    getSizeLimit(type, name) {
      return get(this.product, `attributes.limits[${type}_${name}]`, 0)
    },
    select(parameter, item) {
      const selected = item.attributes.selected
      parameter.items.map(item => (item.attributes.selected = false))
      item.attributes.selected = !selected
      this.updateProduct()
    },
    toggleOpen(id) {
      const open = findIndex(this.openedParams, i => i === id)
      if (open === -1) {
        this.openedParams.push(id)
      } else {
        this.openedParams = this.openedParams.filter(p => p !== id)
      }
    },
    isOpen(id) {
      const open = findIndex(this.openedParams, i => i === id)
      return open !== -1
    },
    getParameterPriceChange(item) {
      const { originalWithTax, selected, default_price } = item.attributes || {}
      const price = default_price ? 0 : originalWithTax
      return this.$currencyFormat(price)
    },
    detectStickyState(value) {
      this.isStickyActive = value
      this.$emit('isStickyActive', value)
    },
    submitSize() {
      this.$nuxt.$emit('updateProductByAttributes', {
        width: this.sizes.width,
        height: this.sizes.height,
        configuration: this.configuration.map(c => ({ [c.code]: Number(c.selected) })),
        extras: { externalId: this.product.externalId },
      })
    },
    updateProduct() {
      this.$nuxt.$emit('updateProductByAttributes', {
        width: this.productWidth,
        height: this.productHeight,
        configuration: this.configuration.map(c => ({ [c.code]: Number(c.selected) })),
        extras: { externalId: this.product.externalId },
      })
    },
  },
}
