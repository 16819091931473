import { find, uniqBy } from 'lodash'
import BusinessUserMixin from '~/mixins/BusinessUserMixin'
import InfiniteText from '@theme/components/utils/InfiniteText'
import PageProductDetail from 'themes/base/components/product/PageProductDetail'

export default {
  extends: PageProductDetail,
  components: {
    InfiniteText,
  },
  data() {
    return {
      tabsBreakpoint: 'md',
    }
  },
  computed: {
    activeVariant() {
      const { variant_id } = this.$route.query
      const id = variant_id && !isNaN(variant_id) ? Number(variant_id) : this.product.defaultVariantId
      return find(this.product.variants, v => v.id === id) || this.product.variants[0]
    },
    showColorVariants() {
      return (
        this.$themeSettings.components.PageProductDetail.showColorVariants &&
        this.alternativeProducts &&
        this.alternativeProducts.length > 0
      )
    },
    parameters() {
      const parameters = PageProductDetail.computed.parameters.apply(this, arguments)
      return uniqBy(parameters, 'entityId')
    },
  },
  methods: {
    getEnabledTabsAccordingToProjectSettings() {
      let tabs = []
      if (this.$themeSettings.components.PageProductDetail.showDescription) {
        tabs.push('description')
      }
      if (this.$themeSettings.components.PageProductDetail.showProductComments) {
        tabs.push('comments')
      }
      if (
        this.$store.state.globals.settings.functions.products_user_reviews &&
        this.$themeSettings.components.PageProductDetail.reviews.enable
      ) {
        tabs.push('reviews')
      }
      return tabs
    },
  },
  mixins: [BusinessUserMixin],
}
