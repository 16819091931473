import ProductCommentReply from '@theme/components/product/ProductCommentReply'

export default {
  components: {
    ProductCommentReply,
  },
  props: {
    comments: Array,
    productId: Number,
  },
}
