import AppImage from '@theme/components/atom/AppImage'
import TooltipOpener from '@theme/components/molecule/TooltipOpener'

export default {
  components: {
    AppImage,
    TooltipOpener,
  },
  props: {
    priceValue: {
      type: Number,
      required: true,
    },
    displayLoginLink: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  computed: {
    creditValue() {
      return Math.round(
        this.priceValue * (parseInt(this.$store.state.globals.settings.integrations.credit_system_percentage) / 100),
      )
    },
  },
}
