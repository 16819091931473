import AppSelectMixin from '~/mixins/AppSelectMixin'

export default {
  mixins: [AppSelectMixin],
  props: {
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    deselect: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: [Object, Array],
      default: () => [],
    },
    optionKeyName: {
      type: String,
      required: false,
    },
    optionKeyValue: {
      type: String,
      required: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    useMultiSelect: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.AppSelect.useMultiSelect
      },
    },
  },
  components: {
    AppMultiSelect: () => import('@theme/components/atom/AppMultiSelect'),
  },
}
