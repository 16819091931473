import { notifications } from '~/app/notifications'
import AppButton from '@theme/components/atom/AppButton'
import AppInput from '@theme/components/atom/AppInput'
import AppTitle from '@theme/components/atom/AppTitle'
import IconLink from '@theme/components/molecule/IconLink'
import LoadingMixin from '~/mixins/LoadingMixin'
import Modal from '@theme/components/utils/Modal'

export default {
  mixins: [LoadingMixin],
  components: {
    AppButton,
    AppInput,
    AppTitle,
    IconLink,
    Modal,
  },
  props: {
    buttonSize: {
      type: String,
      default() {
        return this.$themeSettings.components.ProductAvailabilityWatcher.button.size !== ''
          ? this.$themeSettings.components.ProductAvailabilityWatcher.button.size
          : ''
      },
    },
    buttonStyle: {
      type: String,
      default() {
        return this.$themeSettings.components.ProductAvailabilityWatcher.button.style !== ''
          ? this.$themeSettings.components.ProductAvailabilityWatcher.button.style
          : ''
      },
    },
    isButton: {
      type: Boolean,
      default: true,
    },
    openerClass: String,
    variantId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      slugPrivacyPolicy: this.$t('globals.pageSlugs.privacyPolicy'),
    }
  },
  created() {
    this.CLASS_ELEMENT = 'col-24 col-sm-16 col-md-12 col-lg-11'
    this.CLASS_ELEMENTS_OUTSIDE_NO_LABEL = 'col-md-18 offset-md-6'
    this.CLASS_LABEL = 'col-form-label col-md-6 mb-0'
  },
  methods: {
    openModal() {
      if (this.$store.state.user.userData && !this.email) this.email = this.$store.state.user.userData.email
      this.$refs.modal.show()
    },
    async sendData() {
      const validation = await this.$validator.validate('email')
      if (!validation) return
      this.startLoading()
      const response = await this.$store.dispatch('product/ADD_AVAILABILITY_WATCHER', {
        variantId: this.variantId,
        email: this.email,
      })
      if (response) {
        await notifications.success(this.$t('ProductAvailabilityWatcher.notifications.success'))
        this.$refs.modal.hide()
        this.email = ''
      }
      this.stopLoading()
    },
  },
}
