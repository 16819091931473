import ProductReviewMixin from '~/mixins/ProductReviewMixin'
import StarRating from '@theme/components/utils/StarRating'

export default {
  mixins: [ProductReviewMixin],
  components: {
    StarRating,
  },
  props: {
    review: {
      type: Object,
      required: true,
    },
    conIcon: {
      type: String,
      default() {
        return require('@icon/minus-fat.svg?raw')
      },
    },
    proIcon: {
      type: String,
      default() {
        return require('@icon/plus-fat.svg?raw')
      },
    },
  },
  data() {
    return {
      showExternalReviewLogo: true,
      starsTooltipText: null,
      userName: null,
    }
  },
  created() {
    this.CLASS_COLUMN_LEFT = 'col-24 col-md-6'
    this.CLASS_COLUMN_RIGHT = 'col-24 col-md-18 d-md-flex'
  },
  mounted() {
    this.starsTooltipText = this.$t('ProductReviewBox.averageReviewText', {
      averageRating: this.transformRatingToStars(this.review.rating).toLocaleString(this.$i18n.locale, {
        minimumFractionDigits: this.$themeSettings.components.ProductReviewBox.starsRatingDecimalPlaces,
        maximumFractionDigits: this.$themeSettings.components.ProductReviewBox.starsRatingDecimalPlaces,
      }),
      starsCount: this.$themeSettings.components.StarRating.starsCount,
    })
    this.userName = this.formatUserName(
      this.review.userName,
      this.$themeSettings.components.ProductReviewBox.reviewerName,
      'ProductReviewBox.anonymous',
    )
  },
}
