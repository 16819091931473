import AppInputMixin from '@/mixins/AppInputMixin'
import InjectValidator from '~/app/InjectValidator'

export default {
  mixins: [AppInputMixin, InjectValidator],
  props: {
    autoGrow: {
      type: Boolean,
      default: false,
    },
    errorsInParent: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.autoGrow) {
      this.$nextTick(() => {
        this.$refs.AppTextarea.setAttribute('style', 'height', `${this.$refs.AppTextarea.scrollHeight}px`)
      })
    }
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
  },
  methods: {
    resize(event) {
      event.target.style.height = 'auto'
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    handleInput(event) {
      if (this.autoGrow) this.resize(event)
      this.$emit('input', event.target.value)
    },
  },
}
